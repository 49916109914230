<script>
import VueCropper from 'vue-cropperjs'
import ValidationTemplate from '@/components/general/ValidationTemplate'
import 'cropperjs/dist/cropper.css'
export default {
  components: { VueCropper, ValidationTemplate },
  name: 'ImageUpload',
  data () {
    return {
      selectedImage: null,
      selectedFile: null,
      isSelecting: false,
      dialog: false,
      hover: false
    }
  },
  props: {
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    hideValidation: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: () => 21 / 9
    },
    imageBackground: {
      type: [String, Blob],
      default: null
    },
    defaultImageBackground: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 180
    },
    vWidth: {
      type: Number
    },
    vHeight: {
      type: Number
    },
    rounded: {
      type: Boolean,
      default: false
    },
    zoomable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    imageBackground () {
      this.setSelectedImage()
    }
  },
  methods: {
    onUploadClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      const file = e.target.files[0]
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        if (typeof file === 'object') reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    updateImage () {
      this.selectedImage = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', '0.9')
      this.$emit('updateImageURL', this.selectedImage)
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit('updateImage', blob)
        this.resetInput(true)
      }, 'image/jpeg')
    },
    resetInput (skipSelectedFile = false) {
      if (!skipSelectedFile) this.selectedFile = null
      this.$refs.cropper.replace(null)
      this.$refs.uploader.value = ''
    },
    deleteImage () {
      this.selectedFile = null
      this.selectedImage = null
      this.$emit('removeImage')
    },
    setSelectedImage () {
      if (typeof this.imageBackground === 'string') {
        this.selectedImage = this.imageBackground.trim().length > 0 ? this.imageBackground : null
      }
    },
    clearSelectedImage () {
      this.selectedImage = null
    }
  },
  created () {
    this.setSelectedImage()
  }
}
</script>

<template>
  <validation-template
    :validation="validation"
    :manualValidation="manualValidation"
    :hideValidation="hideValidation"
  >
    <div class="relative-container"
        :style="{ width: (vWidth || width) + 'px', height: (vHeight || height) + 'px' }"
        :class="{'rounded-image': rounded}"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
      <div class="image-upload-overlap" :style="{'height': `${vHeight}px`}"/>
      <transition name="fade">
        <div class="uploader-actions"
            v-if="hover || !selectedImage || isMobile"
        >
          <v-btn
            width="155"
            outlined
            color="#FFFFFF"
            class="text-left my-1 image-upload-btn"
            :class="{'no-selected-file': selectedFile === null}"
            @click="onUploadClick"
          >
            <v-icon class="image-upload--btn-icon">mdi-download</v-icon>
            <span class="image-upload-btn-text">{{$t('global:change.image')}}</span>
          </v-btn>
          <v-btn
            width="35"
            height="35"
            v-if="selectedImage"
            @click="deleteImage"
            small
            color="red"
            class="delete-action mt-1"
          >
            <v-icon color="#FFF">mdi-delete</v-icon>
          </v-btn>
        </div>
      </transition>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      >
      <img
        v-if="selectedImage || defaultImageBackground"
        class="image-upload"
        :class="{ 'default-image': !selectedImage }"
        :src="selectedImage || defaultImageBackground"
        :width="`${vWidth || width}px`"
        :height="`${vHeight || height}px`"
      />
      <v-dialog v-model="dialog" width="700" persistent content-class="cropper-container">
        <div class="cropper">
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedImage"
            alt="Source Image"
            :aspectRatio="aspectRatio"
            :imgStyle="{objectFit: 'cover'}"
            :zoomable="zoomable"
          ></VueCropper>
        </div>
        <div class="cropper-tools">
          <v-btn text small @click="resetInput(), (dialog = false)">
            <v-container>
              <span class="bold">{{$t('global:cancel')}}</span>
            </v-container>
          </v-btn>
          <v-btn text small @click="updateImage(), (dialog = false)">
            <v-container>
              <span class="bold">{{$t('global:cut')}}</span>
            </v-container>
          </v-btn>
        </div>
      </v-dialog>
    </div>
  </validation-template>
</template>

<style lang="scss">
.relative-container {
  position: relative;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  &.rounded-image {
    border-radius: 100px;
    .image-upload-overlap,
    .uploader-actions,
    .image-upload {
      border-radius: 100px;
    }
    .uploader-actions {
      flex-direction: row;
    }
  }
}
.image-upload {
  opacity: 0.5;
  object-fit: cover;
  max-width: 100%;
}
.image-upload-overlap {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0.41) 0%, rgba(0,0,0,0.88) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.41) 0%, rgba(0,0,0,0.88) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.41) 0%, rgba(0, 0, 0, 0.88) 100%) 0% 0% no-repeat padding-box;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.5;
}
.image-upload-btn-text {
  color: #FFFFFF;
}
.cropper {
  overflow: hidden;
  max-height: 80vh;
  > div {
    height: 80vh;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.cropper-tools {
  background: $neutral-white;
  text-align: right;
  .v-btn.v-size--small {
    color: $primary-medium;
    height: 40px;
  }
}
.uploader-actions {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 20;
  > * {
    margin: 0 5px;
    font-weight: bold;
  }
}
.image-upload--btn-icon {
  transform: rotate(180deg);
}
.v-btn.delete-action.v-size--small {
  min-width: 10px;
}
@media only screen and (max-width: 768px) {
  .cropper-container {
    position: relative;
  }
  .cropper-tools {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    background: $primary-medium;
    .v-btn.v-size--small {
      min-width: 50%;
      height: 50px;
      color: $neutral-white;
    }
  }
}
</style>
