<script>
import ValidationTemplate from '@/components/general/ValidationTemplate'
export default {
  name: 'Select',
  components: { ValidationTemplate },
  props: {
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    hideValidation: {
      type: Boolean,
      default: false
    },
    firstOnly: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValidation () {
      if (!this.manualValidation && this.validation) this.validation.$touch()
    }
  }
}
</script>
<template>
  <div class="input-field--wrapper select-field" :class="{'small': small, 'primary-color': primary}">
    <validation-template
      :validation="validation"
      :manualValidation="manualValidation"
      :hideValidation="hideValidation"
    >
      <v-select
        class="body--1"
        v-bind="$attrs"
        v-on="$listeners"
        attach
        @blur="updateValidation"
        hide-details="auto"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
        <template v-if="firstOnly" v-slot:selection="{ item, index, parent }">
          <v-chip v-if="index === 0">
            <v-tooltip v-if="item.text.length > 25" top>
              <template v-slot:activator="{on, attrs}">
                <span v-on="on" v-bind="attrs">{{ item.text.slice(0,24) + '...' }}</span>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <span v-else>{{ item.text }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text caption"
          >
            ({{`+${parent.value.length - 1} ${$t('global:others')}` }})
          </span>

      </template>
      </v-select>
    </validation-template>
  </div>
</template>
<style lang="scss">
.input-field--wrapper.select-field {
  &.small {
    .input-field--wrapper {
      max-width: 200px;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 40px;
    }
    .v-text-field--enclosed .v-input__append-inner {
      margin-top: 9px;
    }
    .v-select__selections {
      padding: 4px 0;
    }
    .v-text-field--outlined .v-label {
      top: 11px;
      color: rgba(0,0,0,.38);
      &.v-label--active {
       color: #000;
       top: 15px;
      }
    }
  }
  &.primary-color {
    .theme--light.v-text-field--outlined>.v-input__control>.v-input__slot>fieldset,
    .v-text-field--outlined > .v-input__control > .v-select__slot {
      color: $primary-medium-faded !important;
    }
  }
}
</style>
